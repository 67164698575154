/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Section from '../components/shared/section';
import ContentBlock from '../components/shared/content-block';
import { setPage, openModal } from '../store/app';
import Button from '../components/shared/button';

const PrivacyCookies = ({ setPage, openModal }) => {
  useEffect(() => {
    setPage('cookies-policy');
  }, [setPage]);

  return (
    <>
      <Section className="bg-grey-light-new p-10">
        <ContentBlock className="new-content-block">
          <h1 style={{ fontSize: 24 }} className="text-center">
            Polityka wykorzystywania plików Cookies w ramach serwisu{' '}
            <a href="/pl/" className="text-teal" style={{ wordBreak: 'break-word' }}>
              https://horsemanago.com/pl/
            </a>
          </h1>
          <hr style={{ margin: '24px 0', height: 1, background: '#292929' }} />
          <div className="my-4">
            <span role="img" aria-label="cookie">🍪 </span>
            {' Drogi użytkowniku, '}
            <Button onClick={() => openModal('MODAL_COOKIES')} className="link p-0">
              pod tym linkiem
            </Button>{' '}
            możesz zarządzać swoimi zgodami.
          </div>
          <div className="mb-6">
            Poniżej znajdziesz treść dotyczącą Polityki wykorzystania plików Cookies w naszym serwisie.
          </div>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>1. Informacje ogólne</h2>
          <p>
            Właścicielem serwisu jest Spółka Vasco Electronics Góralski Group S.K.A. z siedzibą w Krakowie przy al. 29
            Listopada 20, 31-401 Kraków, NIP 6772369151, zwaną w dalszej części „Serwis."
          </p>
          <p>
            Dbamy o poszanowanie prawa do prywatności Użytkowników Serwisu działającego pod nazwą: horsemanago.com/pl/
            Stosujemy rozwiązania organizacyjne i techniczne, w celu zapobiegania ingerowaniu w prywatność naszych
            użytkowników przez osoby trzecie.
          </p>
          <p>
            W ramach naszego serwisu umieszczamy linki, za pomocą których użytkownik może dotrzeć do innych stron
            internetowych. W czasie korzystania z Serwisu, w celu poprawy jego funkcjonalności, poprzez podane linki, na
            Państwa urządzeniu mogą być umieszczane pliki Cookies pochodzące od innych dostawców serwisów jak
            przykładowo: Facebook, Twitter, Google.
          </p>
          <p>
            Polityki prywatności oraz zasady korzystania z Cookies stworzone przez wskazane podmioty są poza kontrolą
            Serwisu. Zalecamy, by przed przystąpieniem do korzystania z innych serwisów zapoznali się Państwo zarówno z
            ich polityką prywatności oraz zasadami wykorzystywania przez nie plików Cookies, w szczególności:
          </p>
          <p>
            Google:{' '}
            <a className="link" href="https://policies.google.com/?hl=pl">
              https://policies.google.com/?hl=pl
            </a>
            <br />
            Facebook:{' '}
            <a className="link" href="https://www.facebook.com/privacy/explanation">
              https://www.facebook.com/privacy/explanation
            </a>
            <br />
            Twitter:{' '}
            <a className="link" href="https://twitter.com/en/privacy">
              https://twitter.com/en/privacy
            </a>
          </p>

          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>2. Słownik</h2>
          <ul>
            <li>
              <p>
                <b>Cookie</b> - jest to informacja zapisywana na komputerze Użytkownika przez Serwis, którą to
                informację Serwis może odczytać przy ponownym połączeniu się z tego komputera.
              </p>
            </li>
            <li>
              <p>
                <b>Log systemowy</b> - informacja zawarta na komputerze użytkownika, która jest przekazywana przez jego
                komputer przy każdym połączeniu. Może zawierać szereg danych, w tym np. numer IP za pomocą, których
                możliwe jest ustalenie przykładowo lokalizacji urządzenia.
              </p>
            </li>
            <li>
              <p>
                <b>Adres IP</b> - indywidualny numer, który posiada każdy komputer podłączony do Internetu. Może być
                statyczny lub dynamiczny w zależności od sposobu związania z urządzeniem (na stałe lub przydzielony).
              </p>
            </li>
            <li>
              <p>
                <b>Adres URL</b> – jest to format adresowania zasobów w Internecie, ale najczęściej mówimy o nim w
                kontekście adresu strony internetowej. Adres strony www jest oparty właśnie na formacie URL.
              </p>
            </li>
          </ul>

          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>3. Gromadzenie danych</h2>
          <p>Przechowujemy zapytania do serwera, które są rozpoznawane poprzez adresy URL i dotyczą:</p>
          <ul>
            <li>
              <p>adresu IP urządzenia końcowego,</p>
            </li>
            <li>
              <p>nazwy stacji użytkownika, jeśli identyfikacja jest możliwa przez protokół http,</p>
            </li>
            <li>
              <p>nazwy użytkownika podanej w procesie autoryzowania,</p>
            </li>
            <li>
              <p>czasu nadejścia zapytania,</p>
            </li>
            <li>
              <p>pierwszego wiersza żądania http.</p>
            </li>
            <li>
              <p>kodu odpowiedzi http, liczby danych wysłanych przez serwer,</p>
            </li>
            <li>
              <p>
                refer link adresu URL strony poprzednio odwiedzanej, w przypadku gdy przejście do serwisu nastąpiło
                przez zewnętrzny serwer,
              </p>
            </li>
            <li>
              <p>informacji o przeglądarce użytkownika,</p>
            </li>
            <li>
              <p>informacji o błędach przy realizacji transakcji.</p>
            </li>
          </ul>
          <p>Dane te nie umożliwiają bezpośrednio identyfikacji osób przeglądających strony serwisu.</p>
          <p>Serwis nie zbiera automatycznie żadnych informacji z wyjątkiem zawartych w plikach cookies.</p>
          <p>Zawsze możesz zmienić ustawienia prywatności swojej wyszukiwarki.</p>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>4. Ciasteczka, czyli Polityka Cookies</h2>
          <p>
            Na naszej stronie używamy Ciasteczek, stosownie do art. 173 Ustawy z dnia 16 lipca 2004 r. prawo
            telekomunikacyjne. Są to dane informatyczne, najczęściej pliki tekstowe, które są przechowywane na
            urządzeniu końcowym użytkownika. Zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas
            przechowywania oraz numer.
          </p>
          <p>Wykorzystujemy pliki Cookies:</p>
          <ul>
            <li>
              <p>
                <b>sesyjne</b> - przechowywane na urządzeniu końcowym do czasu wylogowania lub opuszczenia serwisu,
              </p>
            </li>
            <li>
              <p>
                <b>stałe</b> - przechowywane na urządzeniu końcowym przez czas określony w parametrach cookies lub do
                czasu usunięcia przez użytkownika,
              </p>
            </li>
            <li>
              <p>
                <b>wydajnościowe</b> - zawierające informacje o sposobie korzystania z serwisu,
              </p>
            </li>
            <li>
              <p>
                <b>niezbędne</b> – konieczne do prawidłowej realizacji zamówienia,
              </p>
            </li>
            <li>
              <p>
                <b>funkcjonalne</b> – „pamiętające" ustawienia użytkownika i personalizowanie usługi,
              </p>
            </li>
            <li>
              <p>
                <b>własne</b> – zamieszczane przez serwis,
              </p>
            </li>
            <li>
              <p>
                <b>zewnętrzne</b> - pochodzące z innej witryny niż Serwis.dla celów analitycznych i reklamowych jedynie
                w przypadku wyrażenia przez użytkownika odpowiednich zgód. Brak ich udzielenia nie wpływa negatywnie na
                funkcjonowanie Serwisu.
              </p>
            </li>
          </ul>

          <p>
            Zewnętrzne pliki Cookies wykorzystywane są dla celów analitycznych i/lub reklamowych jedynie w przypadku
            wyrażenia przez użytkownika odpowiednich zgód. Brak ich udzielenia nie wpływa negatywnie na funkcjonowanie
            Serwisu.
          </p>
          <h2 style={{ fontSize: 16, marginTop: 24, marginBottom: 12 }}>
            Żeby zmienić ustawienia prywatności swojej wyszukiwarki:
          </h2>
          <p>
            Informacje o możliwości zmiany ustawień dotyczących ciasteczek w dostępnych najpopularniejszych
            wyszukiwarkach internetowych:
          </p>
          <b>Google Chrome:</b>
          <p>
            Kliknij menu (w prawym górnym rogu), zakładka Ustawienia &gt; Pokaż ustawienia zaawansowane. W sekcji
            „Prywatność" trzeba kliknąć przycisk Ustawienia treści. W sekcji „Pliki cookie" można zmienić następujące
            ustawienia plików cookie: Usuwanie plików cookie, Domyślne blokowanie plików cookie, Domyślne zezwalanie na
            pliki cookie, Domyślne zachowywanie plików cookie i danych stron do zamknięcia przeglądarki, Określanie
            wyjątków dla plików cookie z konkretnych witryn lub domen.
          </p>
          <b>Microsoft Edge:</b>
          <p>
            Z menu przeglądarki (prawy górny róg): Narzędzia &gt; Opcje Internetowe &gt; Prywatność, przycisk Witryny.
            Suwakiem ustawiamy poziom, zmianę zatwierdzamy przyciskiem OK.
          </p>
          <b>Mozilla Firefox:</b>
          <p>
            Z menu przeglądarki: Narzędzia &gt; Opcje &gt; Prywatność. Uaktywnić pole Program Firefox: „będzie używał
            ustawień użytkownika". O ciasteczkach (cookies) decyduje zaklikanie – bądź nie – pozycji Akceptuj
            ciasteczka.
          </p>
          <b>Opera:</b>
          <p>
            Z menu przeglądarki: Narzędzie &gt; Preferencje &gt; Zaawansowane. O ciasteczkach decyduje zaznaczenie –
            bądź nie – pozycji Ciasteczka.
          </p>
          <b>Safari:</b>
          <p>
            W menu rozwijanym Safari trzeba wybrać Preferencje i kliknąć ikonę Bezpieczeństwo. Tu wybiera się poziom
            bezpieczeństwa w obszarze „Akceptuj pliki cookie".
          </p>

          <p>
            Więcej informacji na temat plików cookies dostępnych jest pod adresem{' '}
            <a className="link" href="http://pl.wikipedia.org/wiki/Ciasteczko">
              http://pl.wikipedia.org/wiki/Ciasteczko
            </a>{' '}
            lub w sekcji „Pomoc" w menu przeglądarki internetowej.
          </p>
          <p>Każdy Użytkownik ma prawo do ustawiania poziomu prywatności oraz rodzaju ciasteczek.</p>
          <p>
            Administrator wykorzystuje pliki typu cookies w celu gromadzenia informacji związanych z korzystaniem z
            Serwisu przez Użytkownika. Pliki typu cookies umożliwiają w szczególności:
          </p>
          <ul>
            <li>
              <p>
                utrzymanie sesji Użytkownika (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie
                Serwisu ponownie wpisywać loginu oraz hasła
              </p>
            </li>
            <li>
              <p>dostosowanie Serwisu do potrzeb Użytkowników, tworzenie statystyk oglądalności i analiz Serwisu</p>
            </li>
            <li>
              <p>dostosowanie treści reklamowych do preferencji Użytkownika.</p>
            </li>
            <li>
              <p>
                weryfikacji aktywności użytkownika na stronie w tym ustalenia czy użytkownik nie jest robotem - w ramach
                korzystania z mechanizmu reCAPTCHA dostarczanego przez Google.
              </p>
            </li>
          </ul>

          <p>
            ReCAPTCHA działa poprzez zaimplementowanie algorytmu analizującego aktywność użytkownika poprzez wykonanie
            zrzutu ekranu z okna przeglądarki użytkownika.
          </p>
          <p>
            Jeżeli dana czynność zostanie uznana za mechaniczną (jako działanie robota), jest ona oznaczona przez
            system.
          </p>

          <p>reCAPTCHA zbiera i analizuje następujące dane: </p>
          <ol>
            <li>
              <p>Typowanie wzorców użytkownika</p>
            </li>
            <li>
              <p>Ilość kliknięć myszką, które użytkownik wykonał na stronie lub dotyka aplikacji </p>
            </li>
            <li>
              <p>Język przeglądarki użytkownika </p>
            </li>
            <li>
              <p>Odpowiedzi na pola pytań na stronie </p>
            </li>
            <li>
              <p>Informacje CSS </p>
            </li>
            <li>
              <p>Wtyczki zainstalowane w przeglądarce </p>
            </li>
            <li>
              <p>adresy IP, które zostały wcześniej rozpoznane jako “ludzie" za pomocą ciasteczek.</p>
            </li>
          </ol>
          <p>
            Pytania i zastrzeżenia dotyczące niniejszej Polityki prywatności mogą być przez Państwa zadane pod adresem:{' '}
            <a className="link" href="mailto:gdpr@vasco-electronics.com">
              gdpr@vasco-electronics.pl
            </a>
            .
          </p>
          <p>
            Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich
            dostęp jest operator Serwisu{` `}
            <a className="link" href="https://www.horsemanago.com/pl/">
              https://www.horsemanago.com/pl/
            </a>{' '}
            z siedzibą pod adresem
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Vasco Electronics Góralski Group S.K.A.
            <br />
            al. 29 Listopada 20
            <br />
            31-401 Kraków
            <br />
            e-mail: kontakt@vasco-electronics.pl
            <br />
            tel. +48 12 251 80 66, +48 519 030 919
          </p>
        </ContentBlock>
      </Section>
    </>
  );
};

export default connect(
  state => ({
    pageName: state.app.pageName,
  }),
  dispatch => ({
    setPage: pageName => dispatch(setPage(pageName)),
    openModal: modalName => dispatch(openModal(modalName)),
  })
)(PrivacyCookies);
